import React, { useState } from "react";

export default function TextForm(props) {
  const [text, setText] = useState("Enter text here");
  const onChangeHandler = (e) => {
    setText(e.target.value);
  };
  const clickUpHandler = () => {
    // document.getElementById('myBox').style.textTransform = "uppercase";
    let newText = text.toUpperCase();
    setText(newText);
    props.showAlert('Text coverted to upper case', 'success')
  };
  const clickLoHandler = () =>{
    let newText = text.toLowerCase();
    setText(newText);
    props.showAlert('Text coverted to lower case', 'success')
  }
  const clearText = () =>{
    let newText = '';
    setText(newText);
    props.showAlert('Text has been Cleared', 'success')
  }
  const copyText = () =>{
    let text = document.getElementById('myBox');
    text.select();
    navigator.clipboard.writeText(text.value)
    props.showAlert('Text Copied', 'success')
  }
  const removeSpace = () => {
    let newText = text.split(/[ ]+/);
    setText(newText.join(" "))
    props.showAlert('Whitespace removed!!', 'success')
  }
  return (
    <div>
      <div className="container" style={{fontFamily: 'cursive'}}>
        <h1 style={{color: props.mode === 'light'? 'black':'white'}}>{props.heading}</h1>
        <div className="mb-3">
          <textarea
            className="form-control"
            id="myBox"
            rows="8"
            value={text}
            onChange={onChangeHandler}
            style={{backgroundColor: props.mode === 'light'? 'white':'#214530', color: props.mode === 'light'? 'black':'white'}}
          ></textarea>
        </div>
        <button className="btn btn-primary mx-2" onClick={clickUpHandler}>
          convert to uppercase
        </button>
        <button className="btn btn-primary mx-2" onClick={clickLoHandler}>
          convert to lowercase
        </button>
        <button className="btn btn-primary mx-2" onClick={clearText}>
          clearText
        </button>
        <button className="btn btn-primary mx-2" onClick={copyText}>
          Copy text
        </button>
        <button className="btn btn-primary mx-2" onClick={removeSpace}>
          Remove whitespaces
        </button>
      </div>
      <div className="container my-3" style={{color: props.mode === 'light'? 'black':'white'}}>
        <h1>Text Summary</h1>
        <p>{text.split(" ").filter(word=> word !== "").length} words and {text.length} characters</p>
        <p>{0.008 * text.split(" ").filter(word=> word !== "").length } Minutes Read </p>
        <h3>Preview</h3>
        <p>{text.length > 0 ? text : 'Enter Text above to textarea to preview it here'}</p>
      </div>
    </div>
  );
}
