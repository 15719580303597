import React, { useState } from "react";
import "./App.css";
// import About from "./components/About/About";
import Navbar from "./components/Navbar/Navbar";
import TextForm from "./components/TextForm/TextForm";
import Alert from "./components/Alert/Alert";
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
// } from "react-router-dom";

function App() {
  const [mode, setMode] = useState("light");
  const [btnT, setBtnT] = useState("Enable dark mode");
  const [alert, setAlert] = useState(null);

  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 2000);
  };
  const toggleMode = () => {
    if (mode === "light") {
      setMode("dark");
      setBtnT("Enable light mode");
      document.body.style.backgroundColor = "#042743";
      showAlert("Dark mode has been enabled", "success");
      document.title = "TextUtils - Dark Mode";
    } else {
      setMode("light");
      setBtnT("Enable dark mode");
      document.body.style.backgroundColor = "white";
      showAlert("Light mode has been enabled", "success");
      document.title = "TextUtils - Light Mode";
    }
  };
  return (
    <>
      {/* <Router>
        <Navbar
          title="TextUtils"
          mode={mode}
          toggleMode={toggleMode}
          btn={btnT}
        />
        <Alert alert={alert} />
        <div className="container my-3">
          <Routes>
           <Route exact path="/" element={ <TextForm
              mode={mode}
              heading="Enter text below to analyze"
              showAlert={showAlert}
            />}></Route>
            <Route exact path="/about" element={<About/>}></Route>
          </Routes>
        </div>
      </Router> */}
      <Navbar
        title="TextUtils"
        mode={mode}
        toggleMode={toggleMode}
        btn={btnT}
      />
      <Alert alert={alert} />
      <div className="container my-3">
        <TextForm
          mode={mode}
          heading="Enter text below to analyze"
          showAlert={showAlert}
        />
      </div>
    </>
  );
}

export default App;
