import React from "react";
import PropTypes from 'prop-types'
// import { Link } from "react-router-dom";


export default function Navbar(props) {
  return (
    <div>
      <nav className={`navbar navbar-expand-lg navbar-${props.mode} bg-${props.mode}`}>
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            {props.title}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                {/* <Link className="nav-link active" aria-current="page" to="/">
                  Home
                </Link> */}
                <a className="nav-link active" aria-current="page" href="#">
                  Home
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#">
                  About
                </a>
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </li> */}
            </ul>
            <button className="btn" style={{backgroundColor: props.mode === 'light' ? 'green' : '#042743', color : props.mode === 'light' ? 'black' : 'white', border: '1px solid white'}} onClick={props.toggleMode}>{props.btn}</button>
          </div>
        </div>
      </nav>
    </div>
  );
}
Navbar.propTypes = {
    title: PropTypes.string.isRequired
}
Navbar.defaultProps = {
    title:'Title'
}
